import React from 'react';
import '../styles/UpgradeModal.css';
import config from '../config';

function UpgradeModal({ isOpen, onClose, customerId }) {
  if (!isOpen) return null;

  const subscriptionOptions = [
    {
      title: 'Simple Monthly',
      newPrice: '$10',
      billingCycle: 'Billed Monthly, Cancel Anytime 😌',
      baseUrl: config.subscriptionUrls.newb,
    },
    {
      title: 'Best Deal',
      oldPrice: '$60',
      newPrice: '$34',
      billingCycle: '$34 for 6 months',
      popular: true,
      baseUrl: config.subscriptionUrls.bigBrother,
    },
  ];

  const handleUpgradeClick = (baseUrl) => {
    // Append client_reference_id to the base URL
    const checkoutUrl = `${baseUrl}?client_reference_id=${customerId}`;
    window.location.href = checkoutUrl;
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-title">Choose Your Plan</h2>
        <div className="subscription-options">
          {subscriptionOptions.map((option, index) => (
            <div key={index} className="subscription-tile">
              {option.popular && <span className="popular-badge">Popular</span>}
              <h3>{option.title}</h3>
              <p className="old-price"><s>{option.oldPrice}</s></p>
              <p className="new-price">{option.newPrice}<span> / month</span></p>
              <p className="billing-cycle">{option.billingCycle}</p>
              <button className="upgrade-button" onClick={() => handleUpgradeClick(option.baseUrl)}>
                Upgrade now
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpgradeModal;
