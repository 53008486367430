import React from "react";
import Spinner from "./Spinner";
import "../styles/Sidebar.css";

const ConversationList = ({
  conversations,
  onSelectConversation,
  notifications,
  isLoading,
  error,
}) => {
  return (
    <div className="conversation-list">
      {error && <p className="error-message">{error}</p>}

      {isLoading ? (
        <Spinner size="medium" />
      ) : conversations.length > 0 ? (
        conversations.map((conversation) => (
          <div
            key={conversation.convoId}
            className="conversation-item"
            onClick={() => onSelectConversation(conversation)}
          >
            <img
              src={conversation.photoUrl || "https://via.placeholder.com/40"}
              alt={`${conversation.name}'s avatar`}
              className="conversation-avatar"
            />
            {notifications[conversation.convoId] && (
              <span className="notification-badge">
                {notifications[conversation.convoId]}
              </span>
            )}
          </div>
        ))
      ) : (
        <p className="empty-message">You have no conversations yet.</p>
      )}
    </div>
  );
};

export default ConversationList;
