import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SiPlaycanvas } from "react-icons/si";
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      {/* Company Logo and Name */}
      <div className="footer__brand">
      <Link to="/" className="header__logo">
        <SiPlaycanvas />PressPlay
        </Link>
      </div>

      {/* Contact Info */}
      <div className="footer__contact">
        <p>Say hello: <a href="mailto:hello@pressplay.gg">hello@pressplay.gg</a></p>
      </div>

      {/* Social Media Icons */}
      <div className="footer__social">
        <p>Follow us:</p>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
      </div>

      {/* Links Section */}
      {/* <div className="footer__links">
        <Link to="/blog">Blog</Link>
        <Link to="/cookie-policy">Cookie Policy</Link>
      </div> */}

            {/* Links Section */}
            <div className="footer__links">
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>

      {/* Copyright */}
      <div className="footer__copyright">
        <p>&copy; 2024 PressPlay. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
