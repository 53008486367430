import React from 'react';
import { useNavigate } from 'react-router-dom';
import Signup from '../pages/Signup';

function SignupWrapper(props) {
  const navigate = useNavigate();
  return <Signup {...props} navigate={navigate} />;
}

export default SignupWrapper;
