import React from 'react';
import '../styles/Spinner.css'; // Add the CSS below

const Spinner = ({ size = "medium", message = "" }) => {
  return (
    <div className={`spinner-container ${size}`}>
      <div className="spinner"></div>
      {message && <p className="spinner-message">{message}</p>}
    </div>
  );
};

export default Spinner;
