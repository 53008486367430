import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import ConversationList from "./ConversationList";
import ActiveConversation from "./ActiveConversation";
import "../styles/Sidebar.css";
import { getCurrentUser } from "../Cognito";
import config from "../config";

const Sidebar = ({ onToggle }) => {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [notifications, setNotifications] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      console.log("📡 Fetching current user...");
      const userId = await getCurrentUser();

      if (!userId) {
        console.error("❌ No current user found.");
        return;
      }

      setCurrentUserId(userId);

      try {
        const dynamodb = new AWS.DynamoDB.DocumentClient();
        const params = {
          TableName: config.aws.usersTableName,
          Key: { cognitoId: userId },
        };

        const result = await dynamodb.get(params).promise();
        // console.log("✅ Fetched user profile:", result);

        if (result.Item) {
          setCurrentUser({
            id: userId,
            name: result.Item.name || "You",
            photoUrl: result.Item.photoUrl || "/default-profile.png",
          });
        }
      } catch (error) {
        // console.error("❌ Error fetching user profile:", error);
        setError("Failed to fetch user profile.");
      }
    };

    fetchCurrentUser();
  }, []);

  // Fetch Conversations
  useEffect(() => {
    const fetchConversations = async () => {
      if (!currentUserId) {
        console.log("❌ Current user ID not set, skipping conversation fetch.");
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(
          `${config.apiBaseUrl}/GetConversations?userId=${currentUserId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("✅ Fetched conversations:", data);

        if (data?.conversations?.length > 0) {
          const updatedConversations = data.conversations.map((conversation) => ({
            ...conversation,
            recipientId: conversation.recipientId || null,
          }));

          setConversations(updatedConversations);
        } else {
          console.warn("⚠️ No conversations found.");
        }
      } catch (err) {
        console.error("❌ Error fetching conversations:", err.message);
        setError("Failed to load conversations. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchConversations();
  }, [currentUserId]);

  const handleSelectConversation = (conversation) => {
    if (selectedConversation?.convoId === conversation.convoId) {
      setSelectedConversation(null);
      onToggle(false);
    } else {
      setSelectedConversation(conversation);
      onToggle(true);

      setNotifications((prev) => {
        const updated = { ...prev };
        delete updated[conversation.convoId];
        return updated;
      });
    }
  };

  const handleCloseConversation = () => {
    setSelectedConversation(null);
  };

  const handleNewMessage = (convoId) => {
    if (!selectedConversation || selectedConversation.convoId !== convoId) {
      setNotifications((prev) => ({
        ...prev,
        [convoId]: (prev[convoId] || 0) + 1,
      }));
    }
  };

  // **Conditional Rendering Logic**
  if (isLoading) {
    return <p>Loading conversations...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (conversations.length === 0) {
    console.warn("📭 No conversations to display.");
    return null; 
  }

  return (
    <div className="messaging-sidebar-container">
      <div className="profile-list">
        <ConversationList
          conversations={conversations}
          onSelectConversation={handleSelectConversation}
          notifications={notifications}
        />
      </div>

      {selectedConversation && currentUser && (
        <div className="expanded-sidebar">
          <ActiveConversation
            convoId={selectedConversation.convoId}
            name={selectedConversation.name}
            photoUrl={selectedConversation.photoUrl}
            onNewMessage={handleNewMessage}
            currentUserName={currentUser.name}
            currentUserPhoto={currentUser.photoUrl}
            onCloseConversation={handleCloseConversation}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
