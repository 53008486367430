import React, { Component } from 'react';
import AWS from 'aws-sdk';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { createUser, verifyUser, authenticateUser, getCurrentUser } from '../Cognito';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Signup.css';

// Define the Cognito user pool
const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.clientId,
});

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      verifyCode: '',
      username: '',
      showVerification: false,
      passwordsMatch: true,
      redirect: false, 
    };
  }

  async componentDidMount() {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      this.setState({ redirect: true });
    }
  }

  changeEmail = (e) => this.setState({ email: e.target.value, username: e.target.value });
  changePassword = (e) => this.setState({ password: e.target.value }, this.checkPasswordsMatch);
  changeConfirmPassword = (e) => this.setState({ confirmPassword: e.target.value }, this.checkPasswordsMatch);
  changeVerifyCode = (e) => this.setState({ verifyCode: e.target.value });

  checkPasswordsMatch = () => {
    const { password, confirmPassword } = this.state;
    this.setState({ passwordsMatch: password === confirmPassword });
  };

  handleSignupSubmit = (e) => {
    e.preventDefault();
    const { username, email, password, passwordsMatch } = this.state;

    if (!passwordsMatch) {
      toast.error('Passwords do not match');
      return;
    }

    createUser(username, email, password, (err, result) => {
      if (err) {
        toast.error(err.message || 'An error occurred during signup');
        return;
      }
      toast.success('Sign up successful! Please check your email for the verification code.');
      this.setState({ showVerification: true });
    });
  };

  handleVerifySubmit = async (e) => {
    e.preventDefault();
    const { username, verifyCode, email, password } = this.state;
  
    verifyUser(username, verifyCode, async (err, result) => {
      if (err) {
        toast.error(err.message || "Verification failed. Please try again.");
        return;
      }
      toast.success("Verification successful! Logging you in...");

      authenticateUser(email, password, async (err, loginResult) => {
        if (err) {
          toast.error(err.message || "Login failed. Please try to log in manually.");
          return;
        }

        AWS.config.update({
          region: config.aws.region,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.aws.identityPoolId,
          }),
        });

        try {
          const dynamodb = new AWS.DynamoDB.DocumentClient();
          const params = {
            TableName: config.aws.usersTableName,
            Item: {
              cognitoId: username,
              email: email, 
            },
          };

          await dynamodb.put(params).promise();
          window.location.reload(); 
        } catch (dbError) {
          console.error("Error saving email to Users table:", dbError);
          // toast.error("Failed to save email to the database.");
        }
      });
    });
  };

  resendVerificationCode = () => {
    const { username } = this.state;
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        toast.error(err.message || 'Could not resend code. Please try again.');
        return;
      }
      toast.success('Verification code resent successfully. Please check your email.');
    });
  };

  render() {
    const { showVerification, passwordsMatch, redirect } = this.state;

    // Redirect to /find-team if the user is already logged in
    if (redirect) {
      return <Navigate to="/find-team" />;
    }

    return (
      <div className="container-14">
        <div className="signup-container">
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

          <h2 className="signup-header">Sign Up</h2>

          {!showVerification ? (
            <form onSubmit={this.handleSignupSubmit} className="signup-form">
              <div className="form-group">
                <input
                  className="form-input"
                  value={this.state.email}
                  placeholder="Email"
                  type="email"
                  onChange={this.changeEmail}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  value={this.state.password}
                  placeholder="Password"
                  type="password"
                  minLength={6}
                  onChange={this.changePassword}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  value={this.state.confirmPassword}
                  placeholder="Confirm Password"
                  type="password"
                  minLength={6}
                  onChange={this.changeConfirmPassword}
                  required
                />
              </div>
              {!passwordsMatch && <p className="error-message">Passwords do not match</p>}
              <div className="form-group">
                <button type="submit" className="submit-button" disabled={!passwordsMatch}>
                  Sign Up
                </button>
              </div>
              <div className="form-group">
                <p className="signin-text">
                  Already have an account? <a href="/login" className="signin-link">Sign In</a>
                </p>
              </div>
            </form>
          ) : (
            <form onSubmit={this.handleVerifySubmit} className="verify-form">
              <div className="form-group">
                <input
                  className="form-input"
                  value={this.state.verifyCode}
                  onChange={this.changeVerifyCode}
                  placeholder="Verification Code"
                  required
                />
              </div>
              <div className="form-group">
                <button type="submit" className="submit-button">Verify</button>
                <button
                  type="button"
                  onClick={this.resendVerificationCode}
                  className="resend-button"
                >
                  Resend Code
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default Signup;
