import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignupWrapper from "./components/SignupWrapper";
import Sidebar from "./components/Sidebar";
import FindTeam from "./pages/FindTeam";
import Header from "./components/Header";
import EditProfile from "./pages/EditProfile";
import Blog from "./pages/Blog";
import Waitlist from "./pages/Waitlist";
import Signin from "./pages/Signin";
import UpgradeModal from "./components/UpgradeModal";
import Footer from "./components/Footer";
import { getCurrentUser } from "./Cognito";
import { useTheme } from "./components/ThemeContext";
import "./App.css";

function App() {
  const { theme, toggleTheme } = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

  // Toggle chat sidebar
  const handleSidebarToggle = (isOpen) => {
    setIsChatExpanded(isOpen);
  };

  // Handle login success from child components
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  // Check login status on app mount
  useEffect(() => {
    const checkLoginStatus = async () => {
      // console.log("🔍 Checking login status...");
      try {
        const user = await getCurrentUser();
        setIsLoggedIn(!!user);
        // console.log("✅ User found:", user);
      } catch (error) {
        // console.error("❌ Error checking login status:", error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  // Functions to manage the Upgrade Modal state
  const openUpgradeModal = () => setUpgradeModalOpen(true);
  const closeUpgradeModal = () => setUpgradeModalOpen(false);

  return (
    <div className="App">
      {/* <Header onUpgradeClick={openUpgradeModal} /> */}

      <div className="app-content">
        {isLoggedIn && <Sidebar onToggle={handleSidebarToggle} />} {/* Show Sidebar when logged in */}

        <div className={`main-content ${isChatExpanded ? "expanded" : ""}`}>
          <Routes>
            <Route path="/" element={<HomePage isLoggedIn={isLoggedIn} />} />
            {/* <Route path="/sign-up" element={<SignupWrapper onLoginSuccess={handleLoginSuccess} />} /> */}
            {/* <Route path="/login" element={<Signin onLoginSuccess={handleLoginSuccess} />} /> */}
            {/* <Route path="/find-team" element={<FindTeam isChatExpanded={isChatExpanded} />} /> */}
            <Route path="/waitlist" element={<Waitlist />} />
            {/* <Route path="/edit-profile" element={<EditProfile />} /> */}
          </Routes>
        </div>
      </div>

      <Footer />
      <UpgradeModal isOpen={isUpgradeModalOpen} onClose={closeUpgradeModal} />
    </div>
  );
}

export default App;
