import React, { useState, useEffect, useRef } from "react";
import { getCurrentUser } from "../Cognito";
import config from "../config";
import Spinner from "./Spinner"; 

const ActiveConversation = ({
  convoId,
  name,
  photoUrl,
  onNewMessage,
  currentUserName,
  currentUserPhoto,
  onCloseConversation,
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const messagesEndRef = useRef(null); // Scroll Reference

  useEffect(() => {
    const fetchUserId = async () => {
      const userId = await getCurrentUser();
      if (userId) {
        setCurrentUserId(userId);
      }
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (!convoId) return;

    const fetchMessages = async () => {
      setIsLoading(true); 
      try {
        const response = await fetch(
          `${config.apiBaseUrl}/GetMessages?convoId=${convoId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMessages(data.messages);
      } catch (err) {
        console.error("Error fetching messages:", err.message);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchMessages();
  }, [convoId]);

  // Scroll to the latest message when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  // Set up WebSocket for real-time updates
  useEffect(() => {
    if (!currentUserId) return;

    const ws = new WebSocket(`${config.websocketUrl}?userId=${currentUserId}`);
    setSocket(ws);

    ws.onopen = () => setIsConnected(true);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.convoId === convoId) {
        setMessages((prev) => [...prev, data]);
      }

      onNewMessage(data.convoId); 
    };

    ws.onclose = () => setIsConnected(false);

    return () => ws.close();
  }, [convoId, onNewMessage, currentUserId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); 
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); 
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const messageDate = formatDate(message.timestamp);
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});
  };

   // Poll for new messages every 60 seconds if connected
   useEffect(() => {
    const interval = setInterval(() => {
      if (isConnected && convoId) {
        const fetchMessages = async () => {
          try {
            const response = await fetch(
              `${config.apiBaseUrl}/GetMessages?convoId=${convoId}`
            );
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setMessages(data.messages);
          } catch (err) {
            console.error("Error polling messages:", err.message);
          }
        };

        fetchMessages();
      }
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, [isConnected, convoId]);

  const handleSendMessage = () => {
    if (!newMessage.trim() || !socket || socket.readyState !== WebSocket.OPEN) return;

    const message = {
      action: "sendMessage",
      convoId,
      senderId: currentUserId,
      message: newMessage,
      timestamp: Math.floor(Date.now() / 1000).toString(),
      read: false,
    };

    try {
      setMessages((prev) => [
        ...prev,
        { ...message, senderId: currentUserId, senderName: currentUserName },
      ]);
      setNewMessage(""); 

      socket.send(JSON.stringify(message));
    } catch (error) {
      console.error("Failed to send message via WebSocket:", error);
      alert("Failed to send message. Check your connection.");
    }
  };

  return (
    <div className="chat-window">
      {convoId ? (
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="chat-header">
              <h4>Chat with {name}</h4>
              <button className="close-button" onClick={onCloseConversation}>
                X
              </button>
            </div>
            <div className="chat-messages">
              {Object.entries(groupMessagesByDate(messages)).map(
                ([date, messagesForDate]) => (
                  <div key={date} className="message-group">
                    <div className="date-divider">{date}</div>
                    {messagesForDate.map((message, index) => (
                      <div
                        key={index}
                        className={`chat-message ${
                          message.senderId === currentUserId
                            ? "chat-sent"
                            : "chat-received"
                        }`}
                      >
                        <img
                          src={
                            message.senderId === currentUserId
                              ? currentUserPhoto
                              : photoUrl
                          }
                          alt={`${message.senderId === currentUserId ? currentUserName : name}'s profile`}
                          className="chat-message-profile"
                        />
                        <div className="chat-message-content">
                          <div className="chat-message-header">
                            <span className="chat-message-sender">
                              {message.senderId === currentUserId
                                ? currentUserName
                                : name}
                            </span>
                            <span className="chat-message-timestamp">
                              {formatTimestamp(message.timestamp)}
                            </span>
                          </div>
                          <div className="chat-message-text">
                            {message.message}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <div ref={messagesEndRef} /> 
            </div>
            <div className="message-input-container">
              <textarea
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
              ></textarea>
              <button className="send-button" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </>
        )
      ) : (
        <p>No Messages</p>
      )}
    </div>
  );
};

export default ActiveConversation;
