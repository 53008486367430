import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const root = document.documentElement;

    if (theme === "light") {
      // Light Mode Colors
      root.style.setProperty("--primary-bg", "#FFFFF"); // White
      root.style.setProperty("--primary-text", "#333333"); // Dark Gray
      root.style.setProperty("--secondary-bg", "#F7F9FC"); // Light Blue
      root.style.setProperty("--secondary-text", "#666666"); // Medium Gray
      root.style.setProperty("--cta-bg", "#333333"); // Dark Gray
      root.style.setProperty("--cta-bg-hover", "#4D5055"); // medium gray
      root.style.setProperty("--cta-text", "#FFFFFF"); // White
      root.style.setProperty("--button-hover-bg", "#F2F3F4"); // very light gray
      root.style.setProperty("--chat", "#FFFFFF"); // white
      root.style.setProperty("--highlight","#5271FF"); // Purple
      root.style.setProperty("--accent", "#5271FF"); // Purple
      root.style.setProperty("--accent-hover", "#768EF8"); //Lighter purple
    } else {
      // Dark Mode Colors
      root.style.setProperty("--primary-bg", "#1C1C1E"); // Deep Gray
      root.style.setProperty("--primary-text", "#E4E4E6"); // Light Gray
      root.style.setProperty("--secondary-bg", "#2C2C2E"); // Slightly lighter gray
      root.style.setProperty("--secondary-text", "#A4A4A6"); // Muted gray
      root.style.setProperty("--cta-bg", "#FF7E6C"); // Coral
      root.style.setProperty("--cta-text", "#1C1C1E"); // Deep Gray
      root.style.setProperty("--button-hover-bg", "#FFA55D"); // Orange
      root.style.setProperty("--highlight", "#FFD365"); // Yellow
      root.style.setProperty("--accent", "#78DCE8"); // Sky Blue
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
