import React, { Component } from 'react';
import AWS from 'aws-sdk';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Waitlist.css';

class Waitlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      redirect: false,
    };
  }
  changeEmail = (e) => this.setState({ email: e.target.value });

  handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
  
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  
    if (!email || !emailRegex.test(email)) {
          
        toast("This is a custom toast Notification!", {
            position: "top-left",
            className: "toast-message",
          });
      return;
    }
  
    try {
        const response = await fetch('https://api.brevo.com/v3/contacts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'api-key': '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'
          },
          body: JSON.stringify({
            email: email
          })
        });
  
        if (response.ok) {
      toast("Thanks! You’ve been added to the waitlist, keep an eye out for udpates in your inbox.", {
        position: "top-left",
        className: "toast-message",
      });
    }
      this.setState({ email: '' });
    } catch (dbError) {
      console.error("Error saving email to Subscribers table:", dbError);
      toast("Oops! Something went wrong saving your info. Please try again later.", {
        position: "top-left",
        className: "toast-message",
      });
    }
  };
  

  render() {
    const { redirect, email } = this.state;

    if (redirect) {
      return <Navigate to="/find-team" />;
    }

    return (
        <div className="container-15">
    <section className="home__hero-waitlist">
        <div id="container15" className="home__hero-bg">
          <h1>Find Your Team</h1>
          <p>Connect. Collaborate. Create.</p>
        </div>
    </section>
        <div className="waitlist-container">
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

          <h2 className="waitlist-header">Join Our Waitlist</h2>

          <form onSubmit={this.handleWaitlistSubmit} className="signup-form">
            <div className="form-group">
              <input
                className="form-input"
                value={email}
                placeholder="Email"
                type="email"
                onChange={this.changeEmail}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="submit-button">
                Join Waitlist
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Waitlist;

