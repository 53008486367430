const config = {
    stripePaymentUrl: 'https://billing.stripe.com/p/login/8wMcNS27a4vVezK6oo',
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    websocketUrl: process.env.REACT_APP_WEBSOCKET_URL,
    subscriptionUrls: {
      newb: process.env.REACT_APP_SUBSCRIPTION_URL_NEWB,
      bigBrother: process.env.REACT_APP_SUBSCRIPTION_URL_BIGBRO,
      goat: process.env.REACT_APP_SUBSCRIPTION_URL_GOAT,
    },
    aws: {
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      usersTableName: process.env.REACT_APP_USERS_TABLE_NAME,
      conversationTableName: process.env.REACT_APP_CONVERSATION_TABLE_NAME,
      s3: {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        region: process.env.REACT_APP_S3_REGION,
      },
    },
    cognito: {
      userPoolId: process.env.REACT_APP_USERPOOL_ID,
      clientId: process.env.REACT_APP_CLIENT_ID,
    },
  };
  
  export default config;
  